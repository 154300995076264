@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
  scrollbar-width: none;
}

.icon {
  transition: transform 0.3s ease-in-out;
}
p:hover .icon {
  transform: translateX(2px) translateY(-2px); 
}

div:hover p .icon {
  transform: translateX(2px) translateY(-2px); 
}

/* Custom scrollbar styles */
.scrollbar-thin::-webkit-scrollbar {
  width: 2px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #073736; /* Change this to your desired color */
  border-radius: 8px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background-color: #11756c; /* Change this to your desired color */
}
